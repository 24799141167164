<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="900">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title
          class="white--text"
          v-text="$t('network.chooseAddOns')"
        />
        <v-spacer />
        <v-btn
          color="white"
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-6"
        style="max-height:65vh; height:auto; overflow:auto;"
      >
        <v-data-table
          v-model="addOns"
          :headers="headers"
          :items="addOnList"
          :items-per-page="-1"
          item-key="id"
          show-select
          hide-default-footer
          class="eg-table-bnone eg-table-td-py-md mt-2"
        >
          <template v-slot:item.detailAddOns="{item}">
            <ol class="pl-3">
              <li
                v-for="(detailAddOn, index) in item.detailAddOns"
                :key="index"
                class="my-3"
              >
                <span>{{ $t('network.optionText') }}: {{ detailAddOn.text }}</span><br>
                <span>NRC: {{ detailAddOn.nrc }}</span><br>
                <span>MRC: {{ detailAddOn.mrc }}</span><br>
              </li>
            </ol>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-8 mr-4"
          @click.native="saveAddOns"
        >
          {{ $t("common.save") }}
        </v-btn>

        <v-btn
          class="error px-8"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },

      addOnList: {
        type: Array,
        default: () => [],
      },

      selectedAddOnIds: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    data() {
      return {
        headers: [{
          value: 'index',
          text: this.$t('common.number'),
          width: 40,
          sortable: false,
        }, {
          value: 'text',
          text: this.$t('network.mainText'),
          width: 280,
          sortable: false,
        }, {
          value: 'nrc',
          text: 'NRC',
          width: 80,
          sortable: false,
        }, {
          value: 'mrc',
          text: 'MRC',
          width: 80,
          sortable: false,
        }, {
          value: 'detailAddOns',
          text: this.$t('network.detailAddOns'),
          sortable: false,
        }],
        addOns: [],
      }
    },

    watch: {
      dialog(value) {
        if (!value) {
          return
        }

        for (const item of this.selectedAddOnIds) {
          const selectedAddOn = this.addOnList.find(addOn => addOn.id === item)
          if (selectedAddOn) {
            this.addOns.push(selectedAddOn)
          }
        }
      },
    },

    methods: {
      saveAddOns() {
        this.$emit('save-add-ons', this.addOns.map(addOn => addOn.id))
      },
    }
  }
</script>
