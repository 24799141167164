<template>
  <base-card class="manage-product">
    <template v-if="isEditMode">
      <v-card-title>
        {{ productInfo.displayName }}
      </v-card-title>
      <v-divider />
    </template>
    <v-card-text>
      <v-form
        ref="form"
        v-model="validForm"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" sm="6" md="3" class="pb-0">
            <v-text-field
              :value="networkType"
              disabled
              :label="$t('price.network')"
            />
          </v-col>

          <v-col cols="12" sm="6" md="3" class="pb-0">
            <v-select
              v-model="productInfo.customerType"
              :items="customerTypeList"
              :label="$t('price.customerType')"
              :disabled="!isAdminUser"
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="6" md="3" class="pb-0">
            <v-select
              v-model="productInfo.type"
              :items="typeList"
              :label="$t('price.type')"
              :disabled="!isAdminUser"
              item-text="label"
              item-value="value"
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="6" md="3" class="pb-0">
            <v-select
              v-model="productInfo.term"
              :items="termList"
              :label="$t('price.term')"
              :disabled="!isAdminUser"
              item-text="label"
              item-value="value"
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.downSpeed"
              type="number"
              :disabled="!isAdminUser"
              :label="$t('price.downSpeed')"
              :rules="downloadSpeedRules"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.upSpeed"
              type="number"
              :disabled="!isAdminUser"
              :label="$t('price.upSpeed')"
              :rules="uploadSpeedRules"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.start"
              type="number"
              :label="$t('price.startCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.price"
              type="number"
              :label="$t('price.monthlyCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>
        </v-row>

        <v-row v-if="isAdminUser">
          <v-col cols="12" sm="2" class="pb-0">
            <v-text-field
              v-model="productInfo.bronzeStart"
              type="number"
              :label="$t('price.bronzeStartCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <v-text-field
              v-model="productInfo.bronzePrice"
              type="number"
              :label="$t('price.bronzeMonthlyCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <v-text-field
              v-model="productInfo.silverStart"
              type="number"
              :label="$t('price.silverStartCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <v-text-field
              v-model="productInfo.silverPrice"
              type="number"
              :label="$t('price.silverMonthlyCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <v-text-field
              v-model="productInfo.goldStart"
              type="number"
              :label="$t('price.goldStartCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>

          <v-col cols="12" sm="2" class="pb-0">
            <v-text-field
              v-model="productInfo.goldPrice"
              type="number"
              :label="$t('price.goldMonthlyCost')"
              :rules="requiredRulesWithZero"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.billingItemCode"
              :disabled="!isAdminUser"
              :label="$t('network.billingItemCode')"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.serviceName"
              :disabled="!isAdminUser"
              :label="$t('network.serviceName')"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.serviceAlias"
              :disabled="!isAdminUser"
              :label="$t('network.serviceAlias')"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.serviceTitle"
              :disabled="!isAdminUser"
              :label="$t('price.serviceTitle')"
            />
          </v-col>

          <v-col cols="12" class="pb-0">
            <v-textarea
              v-model="productInfo.serviceHeader"
              :label="$t('price.serviceHeader')"
              row-height="15"
              auto-grow
              hide-details
            />
          </v-col>

          <v-col cols="12" class="pb-0">
            <v-textarea
              v-model="productInfo.serviceDescription"
              :label="$t('price.serviceDescription')"
              row-height="15"
              auto-grow
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.termCancel"
              type="number"
              :disabled="!isAdminUser"
              :label="$t('price.termCancel')"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.deliveryDays"
              type="number"
              :disabled="!isAdminUser"
              :label="$t('price.deliveryDays')"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0">
            <v-text-field
              v-model="productInfo.population"
              :disabled="!isAdminUser"
              :label="$t('access.population')"
            />
          </v-col>

          <v-col cols="12" sm="3" class="pb-0 d-flex justify-space-between align-center">
            <v-checkbox
              v-model="productInfo.isActive"
              :label="productInfo.isActive ? $t('common.active') : $t('common.inActive')"
            />

            <div v-if="productInfo.nodeType" class="pb-0 red--text font-weight-bold">
              {{ $t("price.nodeType") }}: {{ productInfo.nodeType }}
            </div>
          </v-col>
        </v-row>

        <div class="addons-header-wrapper">
          <span class="font-weight-bold text-16">{{ $t("sidebar.addOns") }}</span>
          <v-btn
            class="orange darken-4 white--text"
            small
            @click="showAddOnDialog"
          >
            {{ $t("network.chooseAddOns") }}
          </v-btn>
        </div>

        <div class="addon-item-list">
          <div
            v-for="(addOn, index) in selectedProductAddons"
            :key="index"
            class="shadow-sm mx-1 my-4 rounded-lg secondary pa-4 white--text"
          >
            <div class="add-on-title d-flex align-center justify-space-between">
              <div class="font-weight-bold text-15">
                {{ $t("network.addOn") }}{{ index + 1 }}
                <span :class="addOn.isActive ? 'light-green--text text--accent-3' : 'orange--text text--darken-3'">
                  ({{ addOn.isActive ? "Active" : "Disabled" }})
                </span>
              </div>
              <div
                v-if="isAvailabeDelete(addOn)"
                class="add-on-actions"
              >
                <v-icon
                  @click="removeAddOn(addOn.id, index)"
                  color="orange darken-3"
                >
                  mdi-trash-can-outline
                </v-icon>
              </div>
            </div>
            <div class="ml-4">
              <div v-if="addOn.type === 'checkbox'">
                <v-checkbox
                  dark
                  hide-details
                  class="mt-4 pt-0"
                  color="orange darken-1"
                >
                  <template v-slot:label>
                    <span class="white--text text-14">
                      {{ addOn.text }}
                      (NRC: {{ addOn.nrc }} SEK, MRC: {{ addOn.mrc }} SEK)
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div
                v-if="addOn.type === 'radioGroup'"
                class="mt-4"
              >
                <label class="text-14 font-weight-bold orange--text text--darken-1">{{ addOn.text }}</label>
                <v-radio-group class="mt-2" hide-details>
                  <v-radio
                    v-for="(detailAddon, radioIndex) in addOn.detailAddOns"
                    :key="radioIndex"
                    :value="radioIndex"
                    dark
                    hide-details
                    color="orange darken-1"
                  >
                    <template v-slot:label>
                      <span class="white--text text-14">
                        {{ detailAddon.text }}
                        (NRC: {{ detailAddon.nrc }} SEK, MRC:
                        {{ detailAddon.mrc }} SEK)
                      </span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center mt-4 mb-2">
          <v-btn
            class="primary px-10 mr-4"
            :disabled="!validForm"
            @click="createUpdateProductInfo"
          >
            {{ isEditMode ? $t("network.updateProduct") : $t("network.addProduct") }}
          </v-btn>

          <v-btn
            class="error px-10 mr-4"
            @click="gotoList"
          >
            {{ $t("common.back") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>

    <choose-add-ons-dialog
      v-model="isShowAddOnDialog"
      :add-on-list="selectAvailableAddOns"
      :selected-add-on-ids="selectedAddOnIds"
      @save-add-ons="saveAddOns"
      @cancel="isShowAddOnDialog = false"
    />
  </base-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import _ from 'lodash'

  import util from '@/utils'
  import {
    showSuccessDialog,
    showErrorDialog,
    showQuestionDialog,
  } from '@/utils/swalDialog'

  import ChooseAddOnsDialog from './ChooseAddOnsDialog'

  export default {
    metaInfo: {
      title: 'Manage Product',
    },

    components: {
      ChooseAddOnsDialog,
    },

    data() {
      return {
        networkType: '',
        customerTypeList: ['COMMERCIAL', 'RESIDENTIAL'],
        typeList: [{
          value: 'BASIC',
          label: 'BASIC',
        }, {
          value: 'BASICPLUS',
          label: 'BASICPLUS',
        }, {
          value: 'PRO',
          label: 'PRO',
        }],
        termList: [{
          value: 0,
          label: '0',
        }, {
          value: 12,
          label: '12',
        }, {
          value: 24,
          label: '24',
        }, {
          value: 36,
          label: '36',
        }],
        productId: '',
        productInfo: {
          type: 'BASIC',
          term: 0,
          downSpeed: 0,
          upSpeed: 0,
          start: 0,
          price: 0,
          serviceName: '',
          isActive: true,
          customerType: 'COMMERCIAL',
          serviceTitle: '',
          serviceHeader: '',
          serviceDescription: '',
          termCancel: 0,
          deliveryDays: 0,
          billingItemCode: '',
          serviceAlias: '',
        },
        productAddOns: [],
        isShowAddOnDialog: false,
        addOnList: [],
        selectedAddOnIds: [],
        selectedProductAddons: [],
        downloadSpeedRules: [
          value => !!value || this.$t('error.requiredField'),
          value => Number(value) >= Number(this.productInfo.upSpeed) || this.$t('network.downSpeedGreaterThanUpSpeed')
        ],
        uploadSpeedRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
        requiredRulesWithZero: [
          value => !!value || Number(value) === 0 || this.$t('error.requiredField'),
          value => Number(value) >= 0 || this.$t('error.invalidCost'),
        ],
        validForm: true,
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
        isAdminUser: 'isAdminUser',
      }),

      selectAvailableAddOns() {
        const activeAddOns = this.addOnList.filter((item) => item.isActive)

        if (this.isAdminUser) {
          return activeAddOns
        }

        return activeAddOns
          .filter((item) => item.partnerId)
          .map((item, index) => {
            return {
              ...item,
              index: index + 1,
            }
          })
      },

      isEditMode() {
        return !!this.productId
      }
    },

    mounted() {
      this.loadAddOns()

      this.productId = this.$route.params.productId || ''
      this.networkType = this.$route.query.networkType || ''

      if (this.productId) {
        this.loadProductInfo()
      }
    },

    methods: {
      ...mapActions({
        fetchProduct: 'fetchProduct',
        fetchAddOnList: 'fetchAddOnList',
        addProduct: 'addProduct',
        updateProduct: 'updateProduct',
        updateProductAddons: 'updateProductAddons',
        deleteProductAddOn: 'deleteProductAddOn',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadProductInfo() {
        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        try {
          this.productInfo = await this.fetchProduct(this.productId)

          this.networkType = this.productInfo.network
          this.productInfo.displayName = this.$tc('network.productTitle', !!this.productInfo.nodeType, {
            network: this.productInfo.network,
            speed: this.productInfo.speed,
            type: this.productInfo.type,
            term: this.productInfo.term,
            nodeType: this.productInfo.nodeType,
          }),

          this.selectedProductAddons = this.productInfo.networkAddons
            .map((networkAddOn) => networkAddOn.addOn)
            .filter((addOn) => addOn.isActive)

          this.selectedProductAddons = _.orderBy(
            this.selectedProductAddons,
            ['partnerId'],
            ['desc']
          )

          this.selectedAddOnIds = this.selectedProductAddons
            .filter((networkAddOn) => {
              if (this.isPartnerAdminUser) {
                return !!networkAddOn.partnerId
              }

              return !networkAddOn.partnerId
            })
            .map((networkAddOn) => networkAddOn.id)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
          this.$router.push('/products')
        }
        this.setLoading(false)
      },

      async loadAddOns() {
        try {
          const response = await this.fetchAddOnList({
            offset: 0,
            limit: 100,
            isActive: true,
            isParent: true,
          })

          this.addOnList = response.rows.map((item, index) => {
            return {
              ...item,
              index: index + 1,
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
      },

      async createUpdateProductInfo() {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        if (this.productId) {
          this.updateProductInfo()
        } else {
          this.addProductInfo()
        }
      },

      async addProductInfo() {
        this.setLoadingText(`${this.$t('network.addingProduct')}...`)
        this.setLoading(true)
        try {
          const payload = {
            network: this.networkType,
            type: this.productInfo.type,
            term: this.productInfo.term,
            downSpeed: Number(this.productInfo.downSpeed),
            upSpeed: Number(this.productInfo.upSpeed),
            start: Number(this.productInfo.start),
            price: Number(this.productInfo.price),
            bronzeStart: Number(this.productInfo.bronzeStart),
            bronzePrice: Number(this.productInfo.bronzePrice),
            silverStart: Number(this.productInfo.silverStart),
            silverPrice: Number(this.productInfo.silverPrice),
            goldStart: Number(this.productInfo.goldStart),
            goldPrice: Number(this.productInfo.goldPrice),
            isActive: this.productInfo.isActive,
            serviceName: this.productInfo.serviceName,
            serviceAlias: this.productInfo.serviceAlias,
            serviceTitle: this.productInfo.serviceTitle,
            serviceHeader: this.productInfo.serviceHeader,
            serviceDescription: this.productInfo.serviceDescription,
            termCancel: this.productInfo.termCancel,
            deliveryDays: this.productInfo.deliveryDays,
            customerType: this.productInfo.customerType,
            billingItemCode: this.productInfo.billingItemCode,
            population: this.productInfo.population,
          }

          await this.addProduct(payload)
          this.setLoading(false)

          await showSuccessDialog(this.$t('network.addedProduct'))

          this.$router.push('/products')
        } catch (error) {
          this.setLoading(false)
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
      },

      async updateProductInfo() {
        this.setLoadingText(`${this.$t('network.updatingProduct')}...`)
        this.setLoading(true)
        try {
          const payload = {
            id: this.productInfo.id,
            type: this.productInfo.type,
            term: this.productInfo.term,
            downSpeed: Number(this.productInfo.downSpeed),
            upSpeed: Number(this.productInfo.upSpeed),
            start: Number(this.productInfo.start),
            price: Number(this.productInfo.price),
            bronzeStart: Number(this.productInfo.bronzeStart),
            bronzePrice: Number(this.productInfo.bronzePrice),
            silverStart: Number(this.productInfo.silverStart),
            silverPrice: Number(this.productInfo.silverPrice),
            goldStart: Number(this.productInfo.goldStart),
            goldPrice: Number(this.productInfo.goldPrice),
            isActive: this.productInfo.isActive,
            serviceName: this.productInfo.serviceName,
            serviceAlias: this.productInfo.serviceAlias,
            customerType: this.productInfo.customerType,
            serviceTitle: this.productInfo.serviceTitle,
            serviceHeader: this.productInfo.serviceHeader,
            serviceDescription: this.productInfo.serviceDescription,
            termCancel: this.productInfo.termCancel,
            deliveryDays: this.productInfo.deliveryDays,
            billingItemCode: this.productInfo.billingItemCode,
            population: this.productInfo.population,
          }

          const product = await this.updateProduct(payload)
          this.productInfo.id = product.id
          this.productId = product.id
          showSuccessDialog(this.$t('network.updatedProduct'))
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      showAddOnDialog() {
        this.isShowAddOnDialog = true
      },

      async saveAddOns(addOnIds) {
        this.isShowAddOnDialog = false
        this.selectedAddOnIds = _.cloneDeep(addOnIds)

        this.setLoading(true)
        this.setLoadingText(`${this.$t('network.updatingProductAddOns')}...`)
        try {
          const response = await this.updateProductAddons({
            id: this.productInfo.id,
            addOnIds,
          })

          this.productInfo.id = response.productId
          this.productId = response.productId
          showSuccessDialog(this.$t('network.updatedProductAddOnsSuccessfully'))

          this.loadProductInfo()
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      async removeAddOn(addOnId, index) {
        const btnResponse = await showQuestionDialog(
          this.$t('network.areYouSureRemoveAddOnFromProduct'),
          '',
          this.$t('common.remove')
        )

        if (!btnResponse.value) {
          return
        }

        this.setLoadingText(`${this.$t('network.removingAddOnFromProduct')}...`)
        this.setLoading(true)
        try {
          await this.deleteProductAddOn({
            id: this.productInfo.id,
            addOnId,
          })

          this.selectedProductAddons.splice(index, 1)
          this.selectedAddOnIds = this.selectedProductAddons
            .filter((networkAddOn) => {
              if (this.isPartnerAdminUser) {
                return !!networkAddOn.partnerId
              }

              return !networkAddOn.partnerId
            })
            .map((networkAddOn) => networkAddOn.id)
          showSuccessDialog(this.$t('network.deleteProductAddOnSuccessfully'))
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      isAvailabeDelete(addOn) {
        if (this.isAdminUser) {
          return true
        }

        return !!addOn.partnerId
      },

      gotoList() {
        this.$router.push('/products')
      }
    }
  }
</script>

<style lang="scss">
.manage-product {
  .addons-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 2px;
    margin-top: 10px;
    border-style: solid;
    border-width: 0 0 thin 0;
    border-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.38) 0px, rgba(0, 0, 0, 0.38) 2px, transparent 2px, transparent 4px) 1 repeat;
  }

  .add-on-title {
    border-style: solid;
    border-width: 0 0 thin 0;
    border-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.48) 0px, rgba(255, 255, 255, 0.48) 2px, transparent 2px, transparent 4px) 1 repeat;
  }
}
</style>
